'use client' // Error boundaries must be Client Components

import { useEffect } from 'react'
import './styles/colors.css';
import './globals.css';
import Button from './components/globals/button';
import Image from './components/globals/image';
import CustomLink from './components/globals/link';

export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string }
    reset: () => void
}) {
    useEffect(() => {
        // Log the error to an error reporting service
        //console.error(error)
        console.error('Next.js build error:', error);
    }, [error])

    return (
        <div>
            <div style={{
          width: '100vw',
          height: '200px',
          position: 'relative'
        }}>
          <Image
            lazyLoad={false}
            asset={JSON.parse(localStorage.getItem('headerImage') || '{}') || 'https://a.storyblok.com/f/306333/1920x1080/bd80997aed/christmas.jpg/m/filters:format(webp)'}
            useSource={JSON.parse(localStorage.getItem('headerImage') || '{}') ? false : true}
            quality={70}
            size={{
              width: 1920,
              height: 200
            }}
            sourceSet={{
              '768': {  // Add tablet breakpoint
                size: {
                  width: 820,
                  height: 200
                },
                crop: true
              },
              '1024': {  // Desktop size
                size: {
                  width: 1920,
                  height: 200
                },
                crop: false
              }
            }}
          />
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.27)',
            zIndex: 10,
          }}/>
      </div>
            <div style={{
            width: '100vw',
            height: '58vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div className="container">
                <h1>A little turbulence at sea!</h1>
                <p>Looks like we&apos;ve hit some choppy waters, but don&apos;t worry—we&apos;re navigating back to calm seas. Try refreshing the page or come back shortly.</p>
                <h2>Need a hand?</h2>
                <ul style={{ listStyle: 'inside' }}>
                    <li style={{marginBottom: '10px', color: '#da071b'}}>
                    <CustomLink href='/'>Return to the homepage</CustomLink>
                    </li>
                    <li style={{marginBottom: '10px', color: '#da071b'}}>
                    <CustomLink href='/knowledge-base'>Contact us for assistance</CustomLink>
                    </li>
                </ul>
                <Button
                    style={{ marginTop: '1rem' }}
                    onClick={
                        // Attempt to recover by trying to re-render the segment
                        () => reset()
                    }
                >
                    Try again
                </Button>
            </div>
        </div>
        </div>
    )
}